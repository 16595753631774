import React from 'react';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { RecommendationDto } from '../../apis/florence-facade/florence-facade.dtos';
import { TimeEstimateDisplay } from './TimeEstimateDisplay.component';
import { ContentTypeDisplay } from './ContentTypeDisplay.component';

export type RemediationRecProps = {
  recommendation: RecommendationDto;
  isExpanded: boolean;
  onTitleClick: () => void;
};

const RemediationRecommendation = (props: RemediationRecProps) => {

  if (!props.recommendation) {
    return null;
  }

  const {
    contentType,
  } = props.recommendation;

  const recommendationData = {
    title: 'missing title',
    subtitle: 'missing subtitle',
    timeEstimateSeconds: 0,
    topics: [],
    ...props.recommendation.recommendationData || {} as RecommendationDto['recommendationData'],
  };

  return (
    <div className="c-ssa-remediation-goal-modal__card">

      <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
        <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
          <button
            type="button"
            className="u-els-anchorize c-ssa-remediation-goal-modal__card-title"
            onClick={props.onTitleClick}
          >
            <h4>{recommendationData.title}</h4>
          </button>
        </FlexItem>
        <FlexItem classes={['u-els-nowrap', 'u-els-color-n7']}>
          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.LEFT]}>
            <FlexItem>
              <ContentTypeDisplay contentType={contentType} />
            </FlexItem>
            <FlexItem>
              <TimeEstimateDisplay timeEstimateSeconds={recommendationData.timeEstimateSeconds} />
            </FlexItem>
          </FlexLayout>
        </FlexItem>
      </FlexLayout>

      {props.isExpanded && (
        <div className="u-els-margin-top">

          {recommendationData.subtitle && (
            <div className="o-els-container">
              {recommendationData.subtitle}
            </div>
          )}

          <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.BOTTOM]}>
            <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
              {recommendationData.topics && (
                <div>
                  <div>Topics covered:</div>
                  {
                    recommendationData.topics.map((topic) => {
                      return (
                        <div key={topic}>{topic}</div>
                      );
                    })
                  }
                </div>
              )}
            </FlexItem>
            <FlexItem>
              <button
                type="button"
                className="c-els-button c-els-button--small"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  alert('launch content');
                }}
              >
                Launch
              </button>
            </FlexItem>
          </FlexLayout>
        </div>
      )}

    </div>
  );
};
export default RemediationRecommendation;
