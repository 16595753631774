import React, { Component } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  get,
  isEmpty
} from 'lodash';
import moment from 'moment';
import {
  ELSCommonUIConstants,
  ELSLoggingService,
  ELSPageLoader,
  ELSTokenHelper,
  ELSTokenServiceRegistrar
} from '@els/els-ui-common-react';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import { RoutePath } from '../../components/app/app.constants';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { AppLinkData } from '../../apis/eols-app-link/eols-app-link.dtos';
import { locationActions } from '../../redux/location/location.actions';
import {
  AppLinkRedirectErrorMessage,
  AppLinkRedirectErrorMessages
} from './app-link-redirect.constants';
import { ELSTokenUser } from '../../models/els.dtos';
import { registerMouseFlowService } from '../../components/app/app.config';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { locationSelectors } from '../../redux/location/location.selectors';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { AnalyticsAction } from '../../models/analytics.models';
import { addSearchParams } from '../../utilities/app.utilities';
import { HashLinkDto } from '../../apis/eols-app-link/eols-app-link.utilities';

const mapDispatchToProps = {
  fetchAppLinkData: studyActions.fetchAppLinkData,
  fetchAssignments: studyActions.fetchAssignments,
  fetchCourseSection: studyActions.fetchCourseSection,
  fetchAllAppFeatureFlags: studyActions.fetchAllAppFeatureFlags,
  redirect: locationActions.redirect,
  setIsbns: studyActions.setIsbns,
  setUser: studyActions.setUser,
  setRegisteredToken: studyActions.setRegisteredToken,
  resetStateOnLaunch: studyActions.resetStateOnLaunch,
  trackAction: studyActions.trackAction,
  trackAdobeAction: studyActions.trackAdobeAction,
  setEnableDeepLink: studyActions.setEnableDeepLink,
  fetchHashLinkAction: studyActions.fetchHashLinkAction,
  fetchAssignmentAction: studyActions.fetchAssignmentAction,
  fetchEvolveUsers: studyActions.fetchEvolveUsers,
  setAppLinkCookies: studyActions.setAppLinkCookies,
};

const mapStateToProps = state => ({
  appLinkCookies: studySelectors.getAppLinkCookies(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AppLinkRedirectProps = PropsFromRedux;

export interface AppLinkRedirectState {
  hasError: boolean;
  errorMessage: string;
}

const fileName = 'AppLinkRedirect.page';

interface AppLinkMethodProps {
  token: string;
  linkId: string;
}

export class AppLinkRedirect extends Component<AppLinkRedirectProps, AppLinkRedirectState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    const {
      appLinkCookies,
      setAppLinkCookies,
      location,
      fetchHashLinkAction
    } = this.props;

    const { linkHash } = location.query;

    if (linkHash) {
      fetchHashLinkAction(linkHash).then((response: HashLinkDto) => {
        setAppLinkCookies(response);
        this.launchApp(response);
      });
    } else if (this.isRedirectValid(appLinkCookies)) {
      this.launchApp(appLinkCookies);
    }
  }

  isRedirectValid = (config: AppLinkMethodProps): boolean => {

    const { token, linkId } = config;

    if (!token || !linkId) {
      this.logError(AppLinkRedirectErrorMessage.MISSING_COOKIES, null);
      return false;
    }

    const tokenExpireDate = ELSTokenHelper.getExpirationDateFromToken(token);
    const now = moment();
    if (now.isAfter(tokenExpireDate)) {
      this.logError(AppLinkRedirectErrorMessage.EXPIRED_TOKEN, null);
      return false;
    }

    const tokenUser: ELSTokenUser = ELSTokenHelper.getUserInfoFromToken(token);
    if (isEmpty(tokenUser)) {
      this.logError(AppLinkRedirectErrorMessage.INVALID_TOKEN, null);
      return false;
    }

    const validRoles = [
      ELSCommonUIConstants.userType.Student,
      ELSCommonUIConstants.userType.Instructor
    ];

    if (!validRoles.includes(tokenUser.role)) {
      this.logError(AppLinkRedirectErrorMessage.UNSUPPORTED_USER_ROLE, null);
      return false;
    }

    return true;
  };

  launchApp = (config: AppLinkMethodProps) => {

    const { token, linkId } = config;

    const {
      fetchAppLinkData,
      fetchCourseSection,
      setIsbns,
      setUser,
      setRegisteredToken,
      fetchAllAppFeatureFlags,
      fetchEvolveUsers,
      trackAction
    } = this.props;

    ELSTokenServiceRegistrar.register(token);
    setRegisteredToken(token);
    const tokenUser: ELSTokenUser = ELSTokenHelper.getUser();
    const courseSectionId: string = tokenUser.appParams.courseId ? tokenUser.appParams.courseId.toString() : null;

    setUser({
      userId: tokenUser.userId.toString(),
      courseId: tokenUser.appParams.courseId ? tokenUser.appParams.courseId.toString() : null,
      roleId: tokenUser.role,
      isbns: tokenUser.appParams.isbns
    });

    setIsbns(tokenUser.appParams.isbns);
    registerMouseFlowService();

    fetchEvolveUsers(tokenUser.userId.toString());

    return fetchAppLinkData(linkId).then((appLinkData: AppLinkData) => {
      return Promise.all([
        fetchAllAppFeatureFlags(),
        fetchCourseSection(courseSectionId)
      ])
        .then(([flags]) => {

          trackAction({
            action: AnalyticsAction.APP_LINK_LAUNCH,
            props: {
              linkType: appLinkData.action,
              srcApp: appLinkData.srcApp,
              targetApp: appLinkData.targetApp
            }
          });

          const isMaintenance = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(flags, FEATURE_FLAG.IS_SITE_DOWN_FOR_MAINTENANCE, courseSectionId);
          if (isMaintenance) {
            return Promise.resolve();
          }
          if (appLinkData.targetApp === Application.STUDENT_STUDY) {
            this.handleAppLinkLaunch(appLinkData);
            return Promise.resolve();
          }
          if (appLinkData.srcApp === Application.STUDENT_STUDY) {
            this.handleAppLinkReturn(appLinkData);
            return Promise.resolve();
          }
          return Promise.resolve();
        });
    }).catch((error) => {
      this.logError(AppLinkRedirectErrorMessage.REQUEST_FAILED, error);
    });
  };

  handleAppLinkLaunch = (appLinkData: AppLinkData) => {
    const {
      redirect,
    } = this.props;

    if (
      appLinkData.action === AppAction.LAUNCH
      && appLinkData.outPostBody
      && appLinkData.outPostBody.targetPage === 'CHATBOT_ADMIN'
    ) {
      return redirect(addSearchParams(RoutePath.AI_CHAT_ADMIN, {
        entryId: appLinkData.outPostBody.entryId,
      }));
    }

    if (
      appLinkData.action === AppAction.LAUNCH
      && appLinkData.outPostBody
      && appLinkData.outPostBody.targetPage === 'CHATBOT'
    ) {
      return redirect(RoutePath.AI_CHAT);
    }

    if (
      appLinkData.action === AppAction.LAUNCH
      && appLinkData.outPostBody
      && appLinkData.outPostBody.targetPage === 'AUTH'
    ) {
      return redirect(RoutePath.AUTHORING_POC);
    }

    if (appLinkData.action === AppAction.LAUNCH) {
      return redirect(RoutePath.EAQ_STUDENT_STUDY);
    }

    if (appLinkData.action === AppAction.PERFORMANCE_VIEW
      && appLinkData.outPostBody && appLinkData.outPostBody.targetPage === 'EAQ_STUDENT_TOPIC_REPORT') {
      return redirect(RoutePath.EAQ_STUDENT_TOPIC_REPORT);
    }

    if (
      appLinkData.action === AppAction.ASSESSMENT_START
      && appLinkData.outPostBody
      && appLinkData.outPostBody.targetPage === 'STUDY_ASSIGNMENT_HOME'
    ) {
      return redirect(RoutePath.REMEDIATION_HOME);
    }

    if ((appLinkData.action === AppAction.ASSESSMENT_START)
      && appLinkData.targetApp === Application.STUDENT_STUDY) {
      return redirect(RoutePath.ASSESSMENT_START);
    }

    return Promise.resolve();
  };

  handleAppLinkReturn = (appLinkData: AppLinkData) => {
    const {
      redirect,
    } = this.props;

    if (
      appLinkData.action === AppAction.ASSESSMENT_START
      && appLinkData.targetApp === Application.NHE_ASSESSMENT_PLAYER
      && appLinkData.outPostBody
      && appLinkData.outPostBody.ref === RoutePath.REMEDIATION_HOME
    ) {
      return redirect(addSearchParams(RoutePath.REMEDIATION_QUIZ_SESSION, {
        quizSessionRecommendationId: appLinkData.outPostBody.recommendationId, // For getting recommendation
        quizSessionId: appLinkData.outPostBody.quizSessionId, // For getting session results
        quizSessionRecommendationAttemptId: appLinkData.outPostBody.recommendationAttemptId, // For getting total results
      }));
    }

    if (appLinkData.action === AppAction.ASSESSMENT_START || AppAction.ASSESSMENT_PERFORMANCE_VIEW) {
      if (appLinkData.outPostBody && appLinkData.outPostBody.ref === RoutePath.EAQ_STUDENT_TOPIC_REPORT) {
        return redirect(RoutePath.EAQ_STUDENT_TOPIC_REPORT);
      }

      return redirect(RoutePath.EAQ_STUDENT_STUDY);
    }
    return Promise.resolve();
  };

  logError = (errorType: AppLinkRedirectErrorMessage, error): void => {
    const url = get(error, 'config.url', '');
    const method = get(error, 'config.method', '');
    const status = get(error, 'response.status', '');
    const errorMessage = AppLinkRedirectErrorMessages[errorType];
    this.props.trackAction({
      action: AnalyticsAction.APP_LINK_ERROR,
      props: {
        type: errorType,
        url,
        method,
        status
      }
    });
    ELSLoggingService.error(fileName, errorMessage);
    this.setState({
      hasError: true,
      errorMessage
    });
  };

  render() {
    const { hasError, errorMessage } = this.state;

    if (hasError) {
      return (
        <div className="u-els-padding-3x">
          <h2 className="c-els-heading c-els-heading--h4 u-els-color-primary">{errorMessage}</h2>
        </div>
      );
    }

    return (
      <div className="u-els-padding-3x">
        <h2 className="c-els-heading c-els-heading--h4 u-els-color-primary"><ELSPageLoader /></h2>
      </div>
    );
  }
}

export default connector(AppLinkRedirect);
