import React, {
  useState
} from 'react';
import { ELSButton } from '@els/els-component-button-react';
import { ELSModalService } from '@els/els-component-modal-react';
import {
  ELSCheckBox,
  ELSIcon
} from '@els/els-component-form-field-react';
import { orderBy } from 'lodash';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { AdminChatIsbnBookDto } from '../../apis/florence-facade/florence-facade.dtos';

export type AuthoringIsbnModalProps = {
  handleSave: (selectedEeoIsbns: Record<string, boolean>) => void;
  modalService: ELSModalService;
  selectedEeoIsbns: Record<string, boolean>;
  books: AdminChatIsbnBookDto[];
  modalId: string;
}

export type AuthoringIsbnModalState = {
  selectedEeoIsbns: Record<string, boolean>;
}

const defaultState: AuthoringIsbnModalState = {
  selectedEeoIsbns: {}
};

export const AUTHORING_ISBN_MODAL_ID = 'AUTHORING_ISBN_MODAL_ID';

const AuthoringIsbnModal = (props: AuthoringIsbnModalProps) => {
  const {
    modalService,
  } = props;

  const [state, setState] = useState<AuthoringIsbnModalState>({
    ...defaultState,
    selectedEeoIsbns: props.selectedEeoIsbns
  });

  const handleIsbnCheck = (row: {
    isbn: string;
  }, isSelected: boolean) => {

    setState((prevState) => {
      return {
        ...prevState,
        selectedEeoIsbns: {
          ...prevState.selectedEeoIsbns,
          [row.isbn]: isSelected
        }
      };
    });
  };

  const handleCancel = () => {
    modalService.closeModal(props.modalId);
  };

  const selectAllIsbns = () => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedEeoIsbns: props.books.reduce((acc, book) => {
          return {
            ...acc,
            [book.isbn]: true
          };
        }, {})
      };
    });
  };

  const deselectAllIsbns = () => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedEeoIsbns: {}
      };
    });
  };

  const handleCancelClick = () => {
    props.modalService.closeModal(AUTHORING_ISBN_MODAL_ID);
  };

  return (
    <div>
      <div className="c-els-modal">
        <div className="c-els-modal__window u-els-width-1o1@mobile">
          <button
            type="button"
            className="c-els-modal__close"
            onClick={handleCancelClick}
          >
            <ELSIcon name="close" size="1x" />
            <span className="u-els-hide-visually">Close this modal window</span>
          </button>
          <div>
            <div>Selected books to search in:</div>
            <div>
              <FlexLayout modifiers={[
                FlexLayoutModifier.LEFT,
                FlexLayoutModifier.GUTTERS
              ]}>
                <FlexItem>
                  <button
                    type="button"
                    className="u-els-anchorize"
                    onClick={selectAllIsbns}
                  >
                    select all
                  </button>
                </FlexItem>
                <FlexItem>
                  <button
                    type="button"
                    className="u-els-anchorize"
                    onClick={deselectAllIsbns}
                  >
                    deselect all
                  </button>
                </FlexItem>
              </FlexLayout>
            </div>
            <div className="o-els-container">
              {
                orderBy(props.books, ['title'], ['asc'])
                  .filter((book) => {
                    return book.isActive;
                  })
                  .map((book) => {
                    return (
                      <div
                        key={book.isbn}
                        className="o-els-container o-els-container--1o2"
                      >
                        <ELSCheckBox
                          changeHandler={(e, value) => handleIsbnCheck({ isbn: book.isbn }, value)}
                          checked={state.selectedEeoIsbns[book.isbn]}
                        >
                          <div>{book.title} ({book.editionText})</div>
                          <div><small>{book.author} - {book.isbn}</small></div>
                        </ELSCheckBox>
                      </div>
                    );
                  })
              }
            </div>
          </div>
          <div>
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.WRAP,
              FlexLayoutModifier.LEFT
            ]}>
              <FlexItem>
                <ELSButton type={ELSButtonType.PRIMARY}
                           size={ELSButtonSize.SMALL}
                           onClick={() => {
                             props.handleSave(state.selectedEeoIsbns);
                           }}>
                  Save
                </ELSButton>
              </FlexItem>
              <FlexItem>
                <ELSButton type={ELSButtonType.SECONDARY}
                           size={ELSButtonSize.SMALL}
                           onClick={handleCancel}>
                  Cancel
                </ELSButton>
              </FlexItem>
            </FlexLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthoringIsbnModal;
