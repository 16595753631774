/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
// TODO: Address these lint issues

import { orderBy } from 'lodash';
import moment from 'moment';
import {
  HesiExamRemediationSettingsDto,
  QuizSessionQuestionDto,
  QuizSessionResultsDto,
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RecommendationItemDto,
  RemediationActivityType,
  RemediationActivityTypeDto,
  RemediationAssignmentDto,
  RemediationAssignmentSettingsTypeDto,
  RemRecContentTypeDto,
  RemRecItemTypeDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { RemediationHomeProps } from './RemediationHome.page';
import {
  RecommendationItemStatusMap,
  RecommendationStatusMap,
  RemediationContentTypeConfigMap,
  RemediationGoalType,
  RemediationStatusCountLabel,
  RemediationStatusCounts,
  StatusMapConfig
} from './remediation-home.models';
import {
  AssessmentDto,
  AssessmentGoalDto,
  AssessmentStatusDto,
  AssessmentTypeDto,
  AssignmentDto,
  AssignmentGradeType,
  AssignmentScoreType,
  AssignmentTargetType,
  AssignmentType,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export const getParentLinkId = (props: RemediationHomeProps): string => {
  const { appLinkData, appLinkCookies } = props;
  if (appLinkData && appLinkData.parentLinkId) {
    return appLinkData.parentLinkId;
  }
  return appLinkCookies ? appLinkCookies.linkId : null;
};

export const isRecommendationPartOfGoal = (recommendation: RecommendationDto, assessmentGoal: AssessmentGoalDto): boolean => {
  if (!recommendation || !assessmentGoal) {
    return false;
  }
  return recommendation.assessmentGoalVtwId === assessmentGoal.vtwId && recommendation.assessmentGoalType === assessmentGoal.type;
};

export const generateRecommendation = (
  contentType: RemRecContentTypeDto,
  assessmentGoalVtwId: string,
  assessmentGoalType: string,
): RecommendationDto => {
  const id = Math.floor(Math.random() * 10000000);
  return {
    id,
    assessmentId: -1,
    userId: -1,
    courseSectionId: -1,
    contentType,
    contentId: 'abc',
    status: 'TODO',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recommendationData: {
      title: `Recommendation example title for ${contentType} (topic: ${assessmentGoalVtwId} / id:${id})`,
      timeEstimateSeconds: 500,
      topics: ['Topic 1', 'Topic 2'],
      skipReason: null,
      subtitle: 'This is an example longer form description of the content item'
    },
    remediationAssignmentId: null,
    createdAt: null,
    updatedAt: null,
    assessmentGoalVtwId,
    assessmentGoalType,
  };
};

export const generateMockAssignment = (): AssignmentDto => {
  return {
    assignmentGoals: [],
    assignmentGradeType: AssignmentGradeType.NOT_GRADED,
    assignmentOptions: [],
    assignmentQuestions: [],
    assignmentScoreType: AssignmentScoreType.BEST,
    assignmentTopics: [],
    assignmentType: AssignmentType.STUDENT_STUDY,
    availableDate: '2024-10-01',
    courseSectionId: null,
    createdAt: null,
    dueDate: moment('2024-12-01').toISOString(),
    elsAssignmentId: 'mockAssignmentId',
    endDate: moment('2021-01-01').toISOString(),
    gradePoints: 100,
    id: 1,
    isbn: null,
    lmsAssignmentGuid: null,
    sendResultToGradebook: false,
    studentAssignments: [],
    students: [],
    studyMode: false,
    targetType: AssignmentTargetType.COURSE,
    title: 'Example Remediation Assignment Title',
    subtitle: null,
    updatedAt: null,
    userId: 1,
    contentId: null
  };
};

export const generateMockRemediationAssignment = (): RemediationAssignmentDto => {
  return {
    id: 1,
    assignmentId: 1,
    createdByUser: 1,
    updatedByUser: 1,
    courseSectionId: 1,
    settings: {
      type: RemediationAssignmentSettingsTypeDto.HESI_EXAM_SETTINGS,
      rootTaxonomyId: null,
      taxonomyNodeLevel: null,
      activityTypes: [{
        activityType: RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE,
        isActive: true,
        recommendationMultiplier: 1,
        recommendationAdder: 0,
        recommendationScoreRanges: [
          {
            minScore: null,
            maxScore: null,
            numOfItems: 1
          }
        ],
        contentSettings: [{
          contentType: RemRecContentTypeDto.RAAS_QUIZ,
          isActive: true,
          minScoreToPass: 0.8,
          recommendationItemScoreRanges: [{
            minScore: null,
            maxScore: null,
            numOfItems: 50
          }]
        }]
      }]
    },
    createdAt: '2021-01-01',
    updatedAt: '2021-01-01'
  };
};

export const generateMockAssessment = (): AssessmentDto => {
  return {
    id: 999,
    assignmentId: 1,
    assessmentGoals: [{
      goal: 3,
      text: 'Anxiety and mood disorder',
      vtwId: 'A',
      type: `${RemediationActivityType.REVIEW}::${RemediationGoalType.TAXON}`,
      optional: false
    }, {
      goal: 2,
      text: 'Another topic here',
      vtwId: 'B',
      type: `${RemediationActivityType.REVIEW}::${RemediationGoalType.TAXON}`,
      optional: false
    }, {
      goal: 3,
      text: 'Case Studies',
      vtwId: RemRecContentTypeDto.HESI_CASE_STUDY,
      type: `${RemediationActivityType.APPLY}::${RemediationGoalType.CONTENT_TYPE}`,
      optional: false
    }, {
      goal: 1,
      text: 'Quiz Questions',
      vtwId: RemRecContentTypeDto.RAAS_QUIZ,
      type: `${RemediationActivityType.ASSESS}::${RemediationGoalType.CONTENT_TYPE}`,
      optional: false
    }, {
      goal: 1,
      text: 'Quiz Questions',
      vtwId: RemRecContentTypeDto.RAAS_QUIZ,
      type: `${RemediationActivityType.ASSESS}::${RemediationGoalType.CONTENT_TYPE}`,
      optional: false
    }],
    assessmentTopics: null,
    studentAssignment: null,
    userId: 1,
    courseSectionId: 1,
    type: AssessmentTypeDto.STUDENT_STUDY,
    lessonId: null,
    studyMode: false,
    isbn: null,
    contentId: null,
    status: AssessmentStatusDto.IN_PROGRESS
  };
};

export const generateRecommendations = (
  checklistItems: AssessmentGoalDto[],
): RecommendationDto[] => {

  return checklistItems.reduce((acc, cur) => {
    let numOfRecs = cur.goal;
    let contentType;
    if (cur.type === `${RemediationActivityType.REVIEW}::${RemediationGoalType.TAXON}`) {
      contentType = RemRecContentTypeDto.EBOOK_READING;
      numOfRecs += 5;
    }
    if (cur.type === `${RemediationActivityType.APPLY}::${RemediationGoalType.CONTENT_TYPE}`) {
      contentType = RemRecContentTypeDto.HESI_CASE_STUDY;
    }
    if (cur.type === `${RemediationActivityType.ASSESS}::${RemediationGoalType.CONTENT_TYPE}`) {
      contentType = RemRecContentTypeDto.RAAS_QUIZ;
    }
    const recommendations = Array.from({ length: numOfRecs }, () => {
      return generateRecommendation(
        contentType,
        cur.vtwId,
        cur.type
      );
    });
    return acc.concat(recommendations);
  }, []);

};

export const generateRecommendationItems = (
  recommendations: RecommendationDto[],
): RecommendationItemDto[] => {
  return recommendations.reduce((acc, rec) => {
    if (rec.contentType !== RemRecContentTypeDto.RAAS_QUIZ) {
      return acc;
    }
    const items = Array.from({ length: 60 }, () => {
      return {
        id: Math.floor(Math.random() * 10000000),
        itemType: RemRecItemTypeDto.EAQ_QUESTION,
        itemId: 'abc',
        itemData: null,
        remediationRecommendation: rec,
        createdAt: null,
        updatedAt: null
      };
    });
    return [...acc, ...items];
  }, []);
};

const getItemsFromRecommendation = (recommendationId: number, config: StatusMapConfig): RecommendationItemDto[] => {
  return config.recommendationItems.filter((item) => {
    return item.remediationRecommendationId === recommendationId;
  });
};

const getAttemptsFromRecommendation = (recommendationId: number, config: StatusMapConfig): RecommendationAttemptDto[] => {
  return config.recommendationAttempts.filter((item) => {
    return item.remediationRecommendationId === recommendationId;
  });
};

const getResultsFromRecommendationAttempt = (recommendationAttemptId: number, config: StatusMapConfig): QuizSessionResultsDto[] => {
  return config.quizSessionResults.filter((item) => {
    return item.recommendationAttemptId === recommendationAttemptId;
  });
};
export const getTotalCompleted = (recommendation: RecommendationDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(recommendation.id, config);
  return attempts.reduce((acc, attempt) => {
    // TODO: Think about how to capture completed and passed threshold
    if (
      attempt
      && attempt.status === RecommendationAttemptStatusDto.COMPLETED
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const getTotalAttempted = (recommendation: RecommendationDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(recommendation.id, config);
  return attempts.reduce((acc, attempt) => {
    if (
      attempt
      && attempt.status === RecommendationAttemptStatusDto.COMPLETED
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const generateRecommendationStatusMap = (
  config: StatusMapConfig
): RecommendationStatusMap => {

  if (!config) {
    return null;
  }

  return config.recommendations.reduce((acc, recommendation) => {
    return {
      ...acc,
      [recommendation.id]: {
        completed: getTotalCompleted(recommendation, config),
        total: 1,
        attempted: getTotalAttempted(recommendation, config),
        label: RemediationStatusCountLabel.COMPLETED,
      }
    };
  }, {});

};

export const getTotalItemCorrect = (item: RecommendationItemDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(item.remediationRecommendationId, config);

  if (!attempts) {
    return 0;
  }
  return attempts.reduce((acc, attempt) => {
    return getResultsFromRecommendationAttempt(attempt.id, config).reduce((_acc, result) => {
      return result.questions.reduce((__acc, question) => {
        if (question.questionVtwId === item.itemId && question.correct) {
          return __acc + 1;
        }
        return __acc;
      }, _acc);
    }, acc);
  }, 0);
};

export const getTotalItemAttempted = (item: RecommendationItemDto, config: StatusMapConfig): number => {
  const attempts = getAttemptsFromRecommendation(item.remediationRecommendationId, config);

  if (!attempts) {
    return 0;
  }
  return attempts.reduce((acc, attempt) => {
    return getResultsFromRecommendationAttempt(attempt.id, config).reduce((_acc, result) => {
      return result.questions.reduce((__acc, question: QuizSessionQuestionDto) => {
        if (question.questionVtwId === item.itemId) {
          return __acc + 1;
        }
        return __acc;
      }, _acc);
    }, acc);
  }, 0);
};

export const generateRecommendationItemStatusMap = (
  config: StatusMapConfig
): RecommendationItemStatusMap => {

  if (!config) {
    return null;
  }

  return config.recommendations.reduce((acc, recommendation) => {

    const items = getItemsFromRecommendation(recommendation.id, config);

    if (!items) {
      return {
        ...acc,
        [recommendation.id]: null
      };
    }

    return {
      ...acc,
      [recommendation.id]: items.reduce((_acc, item) => {

        const totals: RemediationStatusCounts = {
          completed: getTotalItemCorrect(item, config) > 0 ? 1 : 0,
          total: 1,
          attempted: getTotalItemAttempted(item, config) > 0 ? 1 : 0,
          label: RemediationStatusCountLabel.CORRECT,
          goal: null
        };

        return {
          ..._acc,
          [item.id]: totals
        };
      }, {})
    };
  }, {});

};

export const getHourAndMinuteFromSecond = (second: number): string => {
  if (second === 0) {
    return '0 min';
  }

  let minute = Math.floor(second / 60);
  let hour = 0;

  if (minute === 0) {
    return '1 min';
  }

  if (minute >= 60) {
    hour = Math.floor(minute / 60);
    minute -= 60 * hour;

    if (minute === 0) {
      return `${hour} hr`;
    }

    return `${hour} hr ${minute} min`;
  }

  return `${minute} min`;
};

export const getActivityTypeFromAssessmentGoal = (assessmentGoal: AssessmentGoalDto): RemediationActivityType => {
  return assessmentGoal.type.split('::')[0] as RemediationActivityType;
};

export const getAssessmentGoalsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    recommendations: RecommendationDto[];
    statusMap: Record<string, RemediationStatusCounts>;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    statusMap
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
    goal: 0,
    label: RemediationStatusCountLabel.COMPLETED
  };

  if (!assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal: AssessmentGoalDto) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (getActivityTypeFromAssessmentGoal(assessmentGoal) !== activityType) {
      return acc;
    }

    return recommendations.reduce((_acc, rec) => {

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (rec.assessmentGoalVtwId !== assessmentGoal.vtwId) {
        return _acc;
      }

      if (!statusMap) {
        return _acc;
      }

      // const status = getStatusCounts(rec)
      const status = statusMap[rec.id];

      if (!status) {
        return _acc;
      }

      const newCompleted = _acc.completed + status.completed;
      const newAttempted = _acc.attempted + status.attempted;

      return {
        ..._acc,
        total: _acc.total,
        completed: newCompleted < _acc.total ? newCompleted : _acc.total,
        attempted: newAttempted,
        label: RemediationStatusCountLabel.COMPLETED
      };
    }, {
      ...acc,
      total: acc.total + assessmentGoal.goal,
      goal: acc.total + assessmentGoal.goal
    });
  }, defaultTotal);
};

export const getQuizPassNumber = (remediationAssignment: RemediationAssignmentDto, items: RecommendationItemDto[]): number => {

  if (!items || !items.length) {
    return null;
  }

  if (!remediationAssignment || !remediationAssignment.settings || !remediationAssignment.settings.activityTypes) {
    return items.length;
  }

  const assessActivityTypeConfig = remediationAssignment.settings.activityTypes.find((activityType: HesiExamRemediationSettingsDto['activityTypes'][0]) => {
    return activityType.activityType === RemediationActivityTypeDto.ASSESS_BY_CONTENT_TYPE;
  });

  if (!assessActivityTypeConfig) {
    return items.length;
  }

  const contentSettingConfig = assessActivityTypeConfig.contentSettings.find((contentSetting) => {
    return contentSetting.contentType === RemRecContentTypeDto.RAAS_QUIZ;
  });

  if (!contentSettingConfig) {
    return items.length;
  }

  return Math.round(contentSettingConfig.minScoreToPass * items.length);

};

export const getAssessmentGoalsItemsTotals = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    recommendations: RecommendationDto[];
    recommendationItems: RecommendationItemDto[];
    statusMap: RecommendationItemStatusMap;
    remediationAssignment: RemediationAssignmentDto;
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationItems,
    statusMap,
    remediationAssignment
  } = props;

  const defaultTotal: RemediationStatusCounts = {
    total: 0,
    completed: 0,
    attempted: 0,
    goal: 0,
    label: RemediationStatusCountLabel.CORRECT
  };

  if (!assessmentGoals) {
    return defaultTotal;
  }

  return assessmentGoals.reduce((acc, assessmentGoal) => {

    if (!assessmentGoal) {
      return acc;
    }

    if (getActivityTypeFromAssessmentGoal(assessmentGoal) !== activityType) {
      return acc;
    }

    return recommendations.reduce((_acc, rec) => {

      if (!rec) {
        return _acc;
      }

      if (RemediationContentTypeConfigMap[rec.contentType].activityType !== activityType) {
        return _acc;
      }

      if (!isRecommendationPartOfGoal(rec, assessmentGoal)) {
        return _acc;
      }

      if (!recommendationItems) {
        return _acc;
      }

      const items = recommendationItems.filter((item) => {
        return item.remediationRecommendationId && item.remediationRecommendationId === rec.id;
      });

      return items.reduce((__acc, item) => {

        const status = statusMap[rec.id][item.id];

        if (!status) {
          return __acc;
        }

        const newCompleted = __acc.completed + status.completed;
        const newAttempted = __acc.attempted + status.attempted;

        return {
          ...__acc,
          total: __acc.total,
          completed: newCompleted < __acc.total ? newCompleted : __acc.total,
          attempted: newAttempted,
          label: RemediationStatusCountLabel.CORRECT
        };
      }, {
        ..._acc,
        total: _acc.total + items.length,
        goal: getQuizPassNumber(remediationAssignment, recommendationItems)
      });

    }, acc);
  }, defaultTotal);
};

export const getAssessmentGoalsTotalsCombined = (
  props: {
    assessmentGoals: AssessmentGoalDto[];
    activityType: RemediationActivityType;
    recommendations: RecommendationDto[];
    recommendationItems: RecommendationItemDto[];
    recommendationStatusMap: RecommendationStatusMap;
    recommendationItemStatusMap: RecommendationItemStatusMap;
    remediationAssignment: RemediationAssignmentDto;
  }
): RemediationStatusCounts => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationItems,
    recommendationStatusMap,
    recommendationItemStatusMap,
    remediationAssignment
  } = props;

  if (props.activityType === RemediationActivityType.ASSESS) {
    return getAssessmentGoalsItemsTotals(
      {
        assessmentGoals,
        activityType,
        recommendations,
        recommendationItems,
        statusMap: recommendationItemStatusMap,
        remediationAssignment
      }
    );
  }

  return getAssessmentGoalsTotals({
    assessmentGoals,
    activityType,
    recommendations,
    statusMap: recommendationStatusMap
  });
};

export const getProgressDisplay = (totals: RemediationStatusCounts): string => {
  return `${totals.completed}/${totals.total} ${totals.label}`;
};

export const getContentTypes = (activityType: RemediationActivityType): RemRecContentTypeDto[] => {
  return Object.keys(RemRecContentTypeDto).filter((contentType: RemRecContentTypeDto) => {
    return RemediationContentTypeConfigMap[contentType] && RemediationContentTypeConfigMap[contentType].activityType === activityType;
  }) as RemRecContentTypeDto[];
};

export const getContentTypeDisplay = (contentTypes: RemRecContentTypeDto[]) => {
  return orderBy(contentTypes, (contentType) => RemediationContentTypeConfigMap[contentType].sortOrder)
    .map((contentType: RemRecContentTypeDto) => {
      return RemediationContentTypeConfigMap[contentType].titlePlural;
    }).join(' & ');
};

export const getGoalTypeFromAssessmentGoal = (assessmentGoal: AssessmentGoalDto): RemediationGoalType => {
  if (!assessmentGoal) {
    return null;
  }
  return assessmentGoal.type.split('::')[1] as RemediationGoalType;
};
