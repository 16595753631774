import React, { useState } from 'react';
import { ELSModalService } from '@els/els-component-modal-react';
import { ELSToastService, } from '@els/els-component-toast-react';
import {
  ELSDropDown,
  ELSDropDownOption,
  ELSRadio
} from '@els/els-component-form-field-react';
import { AnalyticsActionProps } from '../../models/analytics.models';
import {
  RecommendationDto,
  RemediationActivityType,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import {
  ActivityTypeConfigMap,
  RemediationGoalType,
  RemediationStatusCounts,
} from './remediation-home.models';
import RemediationRecommendation from './RemediationRecommendation.component';
import {
  getActivityTypeFromAssessmentGoal,
  getAssessmentGoalsTotals,
  getContentTypeDisplay,
  getContentTypes,
  getGoalTypeFromAssessmentGoal
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { ELSButtonIconPosition } from '../../models/button.models';
import RemediationGoalModal from './RemediationGoalModal.component';
import IsRender from '../../components/is-render/IsRender.component';
import { TotalsStatus } from './TotalsStatus.component';

export const RemediationActivityModalId = 'RemediationCategoryModalId';

enum ViewOptionValue {
  TOP_RECOMMENDATION = 'TOP_RECOMMENDATION',
  ALL_RECOMMENDATIONS = 'ALL_RECOMMENDATIONS'
}

const viewOptions: ELSDropDownOption[] = [{
  name: 'Top recommendation',
  value: ViewOptionValue.TOP_RECOMMENDATION
}, {
  name: 'All recommendations',
  value: ViewOptionValue.ALL_RECOMMENDATIONS
}];

enum SkipOptionValue {
  ALREADY = 'ALREADY',
  NO_SENSE = 'NO_SENSE',
  WANT_NEXT = 'WANT_NEXT',
  WANT_OTHER = 'WANT_OTHER',
  OTHER = 'OTHER',
}

const skipOptions: ELSDropDownOption[] = [{
  name: 'I have already completed this activity prior to taking the exam.',
  value: SkipOptionValue.ALREADY
}, {
  name: 'This activity doesn’t make sense for the exam contents.',
  value: SkipOptionValue.NO_SENSE
}, {
  name: 'I want to see the next choice.',
  value: SkipOptionValue.WANT_NEXT
}, {
  name: 'I just want to see all the choices.',
  value: SkipOptionValue.WANT_OTHER
}, {
  name: 'Other',
  value: SkipOptionValue.OTHER
}];

export type RemediationActivityModalProps = {
  modalService: ELSModalService;
  toastService: ELSToastService;
  trackAction: (props: AnalyticsActionProps) => void;
  redirect: (path: string, state?: Record<string, string | number | boolean>) => void;
  recommendations: RecommendationDto[];
  assessmentGoal: AssessmentGoalDto;
  activityType: RemediationActivityType;
  statusMap: Record<string, RemediationStatusCounts>;
}

export type RemediationActivityModalState = {
  activeView: ViewOptionValue;
  activeRecommendation: RecommendationDto;
  showSkipForm: boolean;
  recommendations: RecommendationDto[];
}

const defaultState: RemediationActivityModalState = {
  activeView: ViewOptionValue.TOP_RECOMMENDATION,
  activeRecommendation: null,
  showSkipForm: false,
  recommendations: null
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const RemediationActivityModal = (props: RemediationActivityModalProps) => {

  const totals = getAssessmentGoalsTotals(
    {
      assessmentGoals: [props.assessmentGoal],
      activityType: getActivityTypeFromAssessmentGoal(props.assessmentGoal),
      recommendations: props.recommendations,
      statusMap: props.statusMap
    }
  );

  const [state, setState] = useState<RemediationActivityModalState>(
    {
      ...defaultState,
      recommendations: props.recommendations,
      activeRecommendation: props.recommendations[0],
      activeView: (totals.completed >= totals.total || props.recommendations.length === totals.total)
        ? ViewOptionValue.ALL_RECOMMENDATIONS
        : ViewOptionValue.TOP_RECOMMENDATION
    }
  );

  const mergeState = (newState: Partial<RemediationActivityModalState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationActivityModalId);
  };

  const handleSkipSubmit = () => {
    const nextIndex = state.recommendations.indexOf(state.activeRecommendation) + 1;
    const nextRec = state.recommendations[nextIndex] || state.recommendations[0];
    mergeState({
      activeRecommendation: nextRec,
      showSkipForm: false
    });
  };
  const handleNextRecClick = () => {

    if (!state.activeRecommendation || !state.activeRecommendation.recommendationData) {
      return;
    }

    if (!state.activeRecommendation.recommendationData.skipReason) {
      mergeState({ showSkipForm: true });
      return;
    }
    const nextIndex = state.recommendations.indexOf(state.activeRecommendation) + 1;
    const nextRec = state.recommendations[nextIndex] || state.recommendations[0];
    mergeState({ activeRecommendation: nextRec });
  };

  const getPrevRec = (): RecommendationDto => {
    const nextIndex = state.recommendations.indexOf(state.activeRecommendation) - 1;
    return state.recommendations[nextIndex] || state.recommendations[state.recommendations.length - 1];
  };

  const handlePrevRecClick = () => {
    const prevRec = getPrevRec();
    mergeState({ activeRecommendation: prevRec });
  };

  const isPrevRecDisabled = (): boolean => {
    const prevRec = getPrevRec();
    if (!prevRec || !prevRec.recommendationData) {
      return false;
    }
    return !prevRec.recommendationData.skipReason;
  };

  const contentTypes = getContentTypes(props.activityType);

  const goalType = getGoalTypeFromAssessmentGoal(props.assessmentGoal);

  return (
    <RemediationGoalModal
      handleCancelClick={handleCancelClick}
      totals={totals}
      isComplete={props.assessmentGoal.goal <= totals.completed}
      contentTypes={contentTypes}
      assessmentGoal={props.assessmentGoal}>
      <div>

        <table className="c-ssa-remediation-goal-modal__goal-table">
          <tbody>
            <tr>
              <td>
                <IconWithText iconName="upwards-line-chart" iconPrefix="gizmo">Instructions</IconWithText>
              </td>
              <td>

                <IsRender isRender={totals.completed < props.assessmentGoal.goal && goalType === RemediationGoalType.TAXON}>
                  Review <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> for this topic.
                  We have retrieved relevant review content based on the questions you have missed in this topic.
                </IsRender>

                <IsRender isRender={totals.completed < props.assessmentGoal.goal && goalType === RemediationGoalType.CONTENT_TYPE}>
                  Complete <strong>{props.assessmentGoal.goal} {getContentTypeDisplay(contentTypes)}</strong> to complete requirements for this section.
                </IsRender>

                <IsRender isRender={totals.completed >= props.assessmentGoal.goal}>
                  <h4>All available {ActivityTypeConfigMap[getActivityTypeFromAssessmentGoal(props.assessmentGoal)].title.toLowerCase()} content</h4>
                </IsRender>
              </td>
            </tr>
            <tr>
              <td>
                <IconWithText iconName="statistics" iconPrefix="gizmo">Progress</IconWithText>
              </td>
              <td>
                <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.GUTTERS_1o2]}>
                  <FlexItem>
                    <TotalsStatus completedGoal={props.assessmentGoal.goal} completed={totals.completed} total={totals.total} />
                  </FlexItem>
                  <FlexItem>
                    <IsRender isRender={goalType === RemediationGoalType.TAXON}>
                      {getContentTypeDisplay(contentTypes)} completed in this topic
                    </IsRender>

                    <IsRender isRender={goalType === RemediationGoalType.CONTENT_TYPE}>
                      {getContentTypeDisplay(contentTypes)} requirements met
                    </IsRender>
                  </FlexItem>
                </FlexLayout>
              </td>
            </tr>
          </tbody>
        </table>

        {totals.completed < totals.total && props.recommendations.length > totals.total && (
          <div className="o-els-container o-els-container--2x">
            <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X]}>
              <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                {
                  state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
                    <>
                      The first one we suggest you review is below:
                    </>
                  )
                }
              </FlexItem>
              <FlexItem>

                <FlexLayout modifiers={[
                  FlexLayoutModifier.GUTTERS,
                  FlexLayoutModifier.MIDDLE,
                ]}>
                  <FlexItem>
                    Currently viewing:
                  </FlexItem>
                  <FlexItem>
                    <ELSDropDown
                      value={state.activeView}
                      options={viewOptions}
                      changeHandler={() => {
                        mergeState({
                          activeView: state.activeView === ViewOptionValue.TOP_RECOMMENDATION
                            ? ViewOptionValue.ALL_RECOMMENDATIONS
                            : ViewOptionValue.TOP_RECOMMENDATION
                        });
                      }}
                    />
                  </FlexItem>
                </FlexLayout>
              </FlexItem>
            </FlexLayout>
          </div>
        )}

        {
          state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
            <div className="c-ssa-remediation-goal-modal__card-wrap">
              <div className="c-ssa-remediation-goal-modal__skip-form">
                <div className="o-els-container">Why did you skip the previous recommendation?</div>
                {skipOptions.map((option) => {
                  return (
                    <div
                      className="o-els-container"
                      key={option.value}
                    >
                      <ELSRadio
                        changeHandler={(e, value) => {
                          setState((prevState) => {
                            const updatedRec = {
                              ...prevState.activeRecommendation,
                              recommendationData: {
                                ...prevState.activeRecommendation.recommendationData,
                                skipReason: value
                              }
                            };
                            return {
                              ...prevState,
                              recommendations: prevState.recommendations.map((rec) => {
                                if (rec.id === updatedRec.id) {
                                  return updatedRec;
                                }
                                return rec;
                              }),
                              activeRecommendation: updatedRec
                            };
                          });
                        }}
                        name={option.value as string}
                        value={option.value}
                        checked={state.activeRecommendation.recommendationData.skipReason === option.value}
                      >
                        {option.name}
                      </ELSRadio>
                    </div>
                  );
                })}

              </div>
              <div className="u-els-margin-top">
                <FlexLayout modifiers={[FlexLayoutModifier.RIGHT]}>
                  <FlexItem>
                    <button
                      type="button"
                      className="c-els-button c-els-button--small"
                      onClick={() => {
                        handleSkipSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </FlexItem>
                </FlexLayout>
              </div>
            </div>
          )
        }

        {
          !state.showSkipForm && state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
            <div className="c-ssa-remediation-goal-modal__card-wrap">

              {state.activeRecommendation && (
                <div className="o-els-container">
                  <RemediationRecommendation
                    recommendation={state.activeRecommendation}
                    isExpanded
                    onTitleClick={() => {
                      // eslint-disable-next-line no-alert
                      alert('launch content');
                    }}
                  />
                </div>
              )}

              <div className="o-els-container">
                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_2X, FlexLayoutModifier.MIDDLE]}>
                  <FlexItem modifiers={[FlexLayoutModifier.GROW]}>
                    {
                      !isPrevRecDisabled() && (
                        <button
                          type="button"
                          className="u-els-anchorize"
                          onClick={handlePrevRecClick}
                        >
                          <IconWithText
                            iconName="arrow-left"
                            iconPrefix="hmds"
                            iconPosition={ELSButtonIconPosition.LEFT}
                          >
                            Previous Suggestion
                          </IconWithText>
                        </button>
                      )
                    }
                  </FlexItem>
                  <FlexItem>
                    <button
                      type="button"
                      className="u-els-anchorize"
                      onClick={handleNextRecClick}
                    >
                      <IconWithText
                        iconName="arrow-right"
                        iconPrefix="hmds"
                        iconPosition={ELSButtonIconPosition.RIGHT}
                      >
                        Next Suggestion
                      </IconWithText>
                    </button>
                  </FlexItem>
                </FlexLayout>
              </div>
            </div>
          )
        }

        {
          state.activeView === ViewOptionValue.ALL_RECOMMENDATIONS && (
            <div className="o-els-container o-els-container--2x">
              <div className="c-ssa-remediation-goal-modal__list">
                {state.recommendations && state.recommendations
                  .map((recommendation) => {
                    return (
                      <div
                        key={recommendation.id}
                        className="c-ssa-remediation-goal-modal__list-item"
                      >
                        <RemediationRecommendation
                          recommendation={recommendation}
                          isExpanded={recommendation.id === state.activeRecommendation.id}
                          onTitleClick={() => {
                            mergeState({ activeRecommendation: recommendation });
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )
        }

        {
          state.activeView === ViewOptionValue.TOP_RECOMMENDATION && (
            <div className="u-els-margin-top-3x">
              <IconWithText
                iconName="information"
                iconPrefix="gizmo">
                Learn more about how we&apos;ve personalized your plan and review content suggestions.
              </IconWithText>
            </div>
          )
        }

      </div>
    </RemediationGoalModal>
  );
};

export default RemediationActivityModal;
