import { IconModel } from '../models/common.models';

export enum HmdsUtility {
  WIDTH_1O2 = 'u-els-width-1o2',
  WIDTH_1O1_MOBILE = 'u-els-width-1o1@mobile'
}

export const IconPrefixes = {
  GIZMO: 'gizmo',
  HMDS: 'hmds'
};

export const CommonIcons: { [name: string]: IconModel } = {
  DOWNLOAD: {
    name: 'download',
    prefix: IconPrefixes.GIZMO
  },
  EAQ: {
    name: 'eaq'
  },
  EBOOK: {
    name: 'ebook-reading',
    prefix: IconPrefixes.HMDS
  },
  HOME: {
    name: 'home',
    prefix: IconPrefixes.GIZMO
  },
  PAGE: { // TODO- discuss whether this should be PAGE (more context meaning re: ebooks) or DOC (more aligned)
    name: 'doc',
    prefix: IconPrefixes.GIZMO
  },
  PERFORMANCE: {
    name: 'performance',
    prefix: IconPrefixes.HMDS
  },
  SYLLABUS: {
    name: 'syllabus',
    prefix: IconPrefixes.HMDS
  },
  INFORMATION: {
    name: 'information',
    prefix: IconPrefixes.GIZMO
  }
};
