/* eslint-disable react/prop-types */

import React from 'react';
import cx from 'classnames';
import { ELSIcon, } from '../../components/els.components';
import {
  ActivityTypeConfigMap,
  RecommendationItemStatusMap,
  RecommendationStatusMap,
  RemediationStatusCounts
} from './remediation-home.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  RecommendationDto,
  RecommendationItemDto,
  RemediationActivityType,
  RemediationAssignmentDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getActivityTypeFromAssessmentGoal,
  getAssessmentGoalsTotalsCombined,
  getContentTypeDisplay,
  getContentTypes,
  getProgressDisplay,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export type RemediationActivitySectionProps = {
  activityType: RemediationActivityType;
  assessmentGoals: AssessmentGoalDto[];
  recommendations: RecommendationDto[];
  recommendationItems: RecommendationItemDto[];
  recommendationStatusMap: RecommendationStatusMap;
  recommendationItemStatusMap: RecommendationItemStatusMap;
  onTaxonClick: (assessmentGoal: AssessmentGoalDto) => void;
  remediationAssignment: RemediationAssignmentDto;
};

const RemediationActivitySection = (props: RemediationActivitySectionProps) => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationStatusMap,
    recommendationItems,
    recommendationItemStatusMap,
    remediationAssignment
  } = props;

  const totals = getAssessmentGoalsTotalsCombined({
    assessmentGoals,
    activityType,
    recommendations,
    recommendationStatusMap,
    recommendationItems,
    recommendationItemStatusMap,
    remediationAssignment
  });

  const contentTypes = getContentTypes(activityType);
  const contentTypesDisplay = getContentTypeDisplay(contentTypes);
  const isGoalComplete = (assessmentGoalTotals: RemediationStatusCounts) => {
    if (!assessmentGoalTotals || !assessmentGoalTotals.goal) {
      return false;
    }
    return assessmentGoalTotals.completed >= assessmentGoalTotals.goal;
  };

  return (
    <div className={`c-ssa-remediation-activity-section c-ssa-remediation-activity-section--${activityType.toLowerCase()}`}>

      <div className="c-ssa-remediation-activity-section__checklist-header">

        <div>
          <FlexLayout
            modifiers={[
              FlexLayoutModifier.BOTTOM,
            ]}
          >
            <FlexItem
              modifiers={[
                FlexLayoutModifier.GROW,
              ]}>
              <h3>
                <strong>{ActivityTypeConfigMap[activityType].title}: {contentTypesDisplay}</strong>
              </h3>
            </FlexItem>
            <FlexItem>
              <div className="u-els-color-n7">{getProgressDisplay(totals)}</div>
            </FlexItem>
          </FlexLayout>

        </div>

      </div>

      {
        activityType === RemediationActivityType.ASSESS && (
          <div className="o-els-container">
            You must attempt all <strong>{totals.total}</strong> questions and answer at
            least <strong>{totals.goal}</strong> correctly.
          </div>
        )
      }

      {assessmentGoals && (
        <div>
          {
            assessmentGoals
              .filter((assessmentGoal) => {
                return getActivityTypeFromAssessmentGoal(assessmentGoal) === activityType;
              })
              .map((assessmentGoal) => {

                const assessmentGoalTotals = getAssessmentGoalsTotalsCombined({
                  assessmentGoals: [assessmentGoal],
                  activityType,
                  recommendations,
                  recommendationItems,
                  recommendationStatusMap,
                  recommendationItemStatusMap,
                  remediationAssignment
                });

                return (
                  <button
                    type="button"
                    onClick={() => props.onTaxonClick(assessmentGoal)}
                    key={assessmentGoal.vtwId}
                    className={
                      cx(
                        'u-els-debuttonize',
                        'c-ssa-remediation-activity-section__checklist-item',
                        {
                          'c-ssa-remediation-activity-section__checklist-item--completed': isGoalComplete(assessmentGoalTotals)
                        }
                      )
                    }
                  >
                    <FlexLayout
                      modifiers={[
                        FlexLayoutModifier.MIDDLE,
                        FlexLayoutModifier.GUTTERS_1o2
                      ]}
                    >
                      <FlexItem>
                        <ELSIcon
                          name={isGoalComplete(assessmentGoalTotals) ? 'checkmark' : ''}
                          prefix="hmds"
                          size="1x"
                          customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                        />
                      </FlexItem>
                      <FlexItem
                        modifiers={[
                          FlexLayoutModifier.GROW,
                        ]}>
                        <div className="c-ssa-remediation-activity-section__checklist-item-text">
                          <strong>{assessmentGoal.text}</strong>
                          <span>&middot; </span>
                          <span className="u-els-color-n7">{getProgressDisplay(assessmentGoalTotals)}</span>
                        </div>
                      </FlexItem>
                      <FlexItem>
                        <ELSIcon
                          size="1x"
                          prefix="hmds"
                          name="arrow-right"
                          customClass="c-ssa-remediation-activity-section__checklist-item-icon"
                        />
                      </FlexItem>
                    </FlexLayout>
                  </button>
                );
              })
          }
        </div>
      )}
    </div>
  );
};
export default RemediationActivitySection;
