import React, {
  useEffect,
  useState
} from 'react';
import {
  ELSPropsFromToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { compose } from 'recompose';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import moment from 'moment';
import {
  ELSDropDown,
  ELSDropDownOption,
  ELSIcon
} from '@els/els-component-form-field-react';
import { Line } from 'react-chartjs-2';
import { ELSButton } from '@els/els-component-button-react';
import { ELSInlineLoader } from '@els/els-ui-common-react';
import {
  ChatPerformanceDto,
  StudentThemesStatus,
  StudentThemesStatusDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import withPageLoader from '../../hocs/with-page-loader/withPageLoader.hoc';
import AiChatBaseTemplate from '../../components/ai-chat-base-template/AiChatBaseTemplate.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  CHART_HEIGHT,
  N10,
  N2,
  ORANGE
} from './ai-chat-performance.constants';
import 'chartjs-plugin-datalabels';
import { locationActions } from '../../redux/location/location.actions';
import { RoutePath } from '../../components/app/app.constants';
import {
  ELSButtonSize,
  ELSButtonType
} from '../../models/button.models';
import { AnalyticsAction } from '../../models/analytics.models';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { isFeatureFlag } from '../../utilities/featureFlag.utilities';
import {
  ELSDataTable,
  ELSTab,
  ELSTabGroup
} from '../../components/els.components';

const mapStateToProps = state => ({
  courseSectionId: studySelectors.getCourseSectionId(state),
  featureFlagsGrouped: studySelectors.getFeatureFlagsGrouped(state)
});

const mapDispatchToProps = {
  fetchChatPerformanceAction: studyActions.fetchChatPerformanceAction,
  fetchAllUserCourseSectionsAction: studyActions.fetchAllUserCourseSectionsAction,
  fetchStudentThemesStatusAction: studyActions.fetchStudentThemesStatusAction,
  postStudentThemesAction: studyActions.postStudentThemesAction,
  redirect: locationActions.redirect,
  trackAction: studyActions.trackAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AiChatPerformanceProps = PropsFromRedux & ELSPropsFromToastService;

export type AiChatPerformanceState = {
  selectedCourseSectionId: number;
  performanceDto: ChatPerformanceDto;
  courseSectionOptions: ELSDropDownOption[];
  chart1Width: number;
  isHamburgerMenuOpen: boolean;
  endDateOffset: number;
  themesStatusDtoMap: Record<string, StudentThemesStatusDto>;
  timeoutRef: NodeJS.Timeout;
  activeTabIndex: number;
};

const defaultState: AiChatPerformanceState = {
  selectedCourseSectionId: null,
  performanceDto: null,
  courseSectionOptions: [],
  chart1Width: null,
  isHamburgerMenuOpen: false,
  endDateOffset: 0,
  themesStatusDtoMap: null,
  timeoutRef: null,
  activeTabIndex: 0
};

export const AiChatPerformanceComponent = (props: AiChatPerformanceProps) => {
  const {
    fetchChatPerformanceAction,
    fetchAllUserCourseSectionsAction,
    fetchStudentThemesStatusAction,
    postStudentThemesAction,
    courseSectionId,
    featureFlagsGrouped,
    redirect,
    trackAction
  } = props;

  const [state, setState] = useState<AiChatPerformanceState>({
    ...defaultState,
    selectedCourseSectionId: parseInt(courseSectionId, 10)
  });

  const mergeState = (newState: Partial<AiChatPerformanceState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const getMapKey = (endDateOffset: number, currentCourseSectionId: number): string => {
    return `${endDateOffset}-${currentCourseSectionId}`;
  };

  const getStartDate = (endDateOffset: number): string => {
    return moment().add((endDateOffset - 6), 'days').startOf('day').toISOString();
  };

  const updatePerformance = (endDateOffset: number, courseSectionsId: number) => {
    fetchChatPerformanceAction(getStartDate(endDateOffset), 7, [courseSectionsId]).then((response) => {
      mergeState({
        performanceDto: response
      });
    });
  };

  const pollStatus = (endDateOffset: number, selectedCourseSectionId: number, isTrackComplete: boolean) => {
    fetchStudentThemesStatusAction(getStartDate(endDateOffset), 7, [selectedCourseSectionId])
      .then((response) => {
        setState((prevState) => {
          return {
            ...prevState,
            themesStatusDtoMap: {
              ...prevState.themesStatusDtoMap,
              [getMapKey(endDateOffset, selectedCourseSectionId)]: response
            }
          };
        });

        if (response.status === StudentThemesStatus.ERROR || response.status === StudentThemesStatus.NOT_STARTED) {
          return;
        }

        if (response.status === StudentThemesStatus.IN_PROGRESS) {
          mergeState({
            timeoutRef: setTimeout(() => pollStatus(endDateOffset, selectedCourseSectionId, true), 2000)
          });
          return;
        }

        if (isTrackComplete) {
          trackAction({
            action: AnalyticsAction.AI_CHAT_STUDENT_THEMES_RESULTS,
            props: {
              requestHash: state.themesStatusDtoMap[getMapKey(endDateOffset, selectedCourseSectionId)].requestKey,
              size: response.response.chatThemes.length
            }
          });
        }
      });
  };

  const handleInsightsChange = (endDateOffset: number, selectedCourseSectionId: number, isTrackComplete) => {
    if (state.timeoutRef && state.themesStatusDtoMap) {
      clearTimeout(state.timeoutRef);
    }

    updatePerformance(endDateOffset, selectedCourseSectionId);
    pollStatus(endDateOffset, selectedCourseSectionId, isTrackComplete);
  };

  useEffect(() => {
    handleInsightsChange(state.endDateOffset, state.selectedCourseSectionId, false);

    fetchAllUserCourseSectionsAction().then((courseSections) => {
      mergeState({
        courseSectionOptions: courseSections.map((courseSection) => {
          return {
            value: courseSection.id,
            name: courseSection.courseName
          };
        })
      });
    });
  }, []);

  const getDateRange = () => {

    const endDate = moment().add(state.endDateOffset, 'days').endOf('day');
    const startDate = moment(endDate).add(-6, 'days').startOf('day');

    return {
      endDate,
      startDate
    };

  };

  const isEndDateToday = (): boolean => {
    const { endDate } = getDateRange();
    return moment().endOf('day').isSame(endDate);
  };

  const handleTimeFrameChange = (num: number) => {
    if (num > 0 && isEndDateToday()) {
      return;
    }

    mergeState({
      endDateOffset: state.endDateOffset + num,
      performanceDto: null
    });

    handleInsightsChange(state.endDateOffset + num, state.selectedCourseSectionId, false);
  };

  const handleCourseSectionChange = (e, value) => {
    mergeState({
      selectedCourseSectionId: value,
      performanceDto: null
    });

    handleInsightsChange(state.endDateOffset, value, false);
  };

  const handlePostThemesClick = (isRetry: boolean) => {
    postStudentThemesAction(getStartDate(state.endDateOffset), 7, [state.selectedCourseSectionId])
      .then(() => {
        setState((prevState) => {
          return {
            ...prevState,
            themesStatusDtoMap: {
              ...prevState.themesStatusDtoMap,
              [getMapKey(state.endDateOffset, state.selectedCourseSectionId)]: {
                request: null,
                requestKey: null,
                status: StudentThemesStatus.IN_PROGRESS,
                response: null
              }
            }
          };
        });
        trackAction({
          action: AnalyticsAction.AI_CHAT_STUDENT_THEMES_CLICK,
          props: {
            isRetry
          }
        });

        pollStatus(state.endDateOffset, state.selectedCourseSectionId, true);
      });
  };

  const handleReturnToChatClick = () => {
    redirect(RoutePath.AI_CHAT);
  };

  const renderDateRange = () => {

    const { startDate, endDate } = getDateRange();

    const monthDayFormat = 'MMMM D';
    const yearFormat = 'YYYY';
    if (startDate.isSame(endDate, 'year')) {
      if (startDate.isSame(endDate, 'month')) {
        return `${startDate.format(monthDayFormat)} - ${endDate.format(`D, ${yearFormat}`)}`;
      }
      return `${startDate.format(monthDayFormat)} - ${endDate.format(`${monthDayFormat}, ${yearFormat}`)}`;
    }
    return `${startDate.format(`${monthDayFormat}, ${yearFormat}`)} - ${endDate.format(`${monthDayFormat}, ${yearFormat}`)}`;
  };

  const renderLineChart = () => {

    if (!state.performanceDto || !state.performanceDto.perDayMetrics) {
      return null;
    }

    const perDayMetrics = state.performanceDto.perDayMetrics.map((day) => {
      return {
        ...day,
        label: moment.utc(day.startDate).local().format('MM/DD')
      };
    });

    const defaultDataSet = {
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 10,
      lineTension: 0,
    };

    const data = {
      labels: perDayMetrics.map((item) => {
        return item.label;
      }),
      datasets: [
        {
          ...defaultDataSet,
          label: 'Student count',
          fill: true,
          lineTension: 0.5,
          backgroundColor: N2,
          borderColor: N2,
          pointRadius: 1,
          pointBorderColor: N2,
          pointHoverBackgroundColor: N2,
          pointHoverBorderColor: N2,
          data: perDayMetrics.map((item) => {
            return item.studentCount;
          })
        },
        {
          ...defaultDataSet,
          label: 'Query count',
          fill: false,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          pointBorderColor: ORANGE,
          pointHoverBackgroundColor: ORANGE,
          pointHoverBorderColor: ORANGE,
          data: perDayMetrics.map((item) => {
            return item.queryCount;
          })
        }
      ]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            stepSize: 100
          }
        }],
      },
      plugins: {
        datalabels: {
          display: (context) => {
            return context.datasetIndex === 1; // only display labels for second dataset
          },
          color: N10,
          align: 'top',
          formatter: (item) => {
            return item;
          }
        }
      }
    };

    return (
      <div className="c-ssa-ai-chat-performance__chart">

        <div className="u-els-margin-bottom">
          <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.GUTTERS_2X]}>
            <FlexItem>
              <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS]}>
                <FlexItem>
                  <div className="c-ssa-ai-chat-performance__chart-cir" />
                </FlexItem>
                <FlexItem>
                  <h4>Queries</h4>
                </FlexItem>
              </FlexLayout>
            </FlexItem>
            <FlexItem>
              <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE, FlexLayoutModifier.GUTTERS]}>
                <FlexItem>
                  <div className="c-ssa-ai-chat-performance__chart-mtn" />
                </FlexItem>
                <FlexItem>
                  <h4>Students</h4>
                </FlexItem>
              </FlexLayout>
            </FlexItem>
          </FlexLayout>

        </div>

        <div style={{ width: '100%', height: CHART_HEIGHT }}>
          <Line data={data} options={options} />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (!state.performanceDto || !state.performanceDto.perDayMetrics) {
      return null;
    }

    const perDayMetrics = state.performanceDto.perDayMetrics.map((day) => {
      return {
        ...day,
        date: moment.utc(day.startDate).local().format('MMMM D')
      };
    });

    return (
      <ELSDataTable
        data={perDayMetrics}
        noWrap
        id="instructor-insights-table"
      >
        <column field="date" header="Date" />
        <column field="studentCount" header="Student Count" />
        <column field="queryCount" header="Query Count" />
      </ELSDataTable>
    );
  };

  const getAverageStudentsPerDay = (): number => {
    if (!state.performanceDto || !state.performanceDto.perDayMetrics) {
      return null;
    }

    const total = state.performanceDto.perDayMetrics.reduce((acc, item) => {
      return acc + item.studentCount;
    }, 0);

    return Math.floor(total / state.performanceDto.perDayMetrics.length);
  };

  const performanceHasResponse = state.performanceDto;
  const performanceHasNoResults = state.performanceDto
    && (!state.performanceDto.perDayMetrics || state.performanceDto.perDayMetrics.every((item) => {
      return item.studentCount === 0 && item.queryCount === 0;
    }));

  const noStudentEngagement = (): boolean => {
    return performanceHasResponse && performanceHasNoResults;
  };

  const handleTabChange = (index: number) => {
    mergeState({
      activeTabIndex: index
    });
  };

  const performanceDisplay = () => {
    return (
      <>
        {noStudentEngagement() && (
          <div>
            No engagement for this week
          </div>
        )}

        {performanceHasResponse && !performanceHasNoResults && (
          <div className="o-els-container o-els-container--3x">
            <ELSTabGroup
              onChangeActiveIndex={handleTabChange}
              activeIndex={state.activeTabIndex}
            >
              <ELSTab title="Chart view">
                {renderLineChart()}
              </ELSTab>
              <ELSTab title="Table view">
                {renderTable()}
              </ELSTab>
            </ELSTabGroup>
          </div>
        )}

        {
          performanceHasResponse && (
            <>
              <div className="o-els-container o-els-container--3x">

                <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_3X, FlexLayoutModifier.LEFT]}>
                  <FlexItem>

                    <FlexLayout
                    modifiers={[FlexLayoutModifier.GUTTERS, FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem>
                        <h1 id="total-queries-per-week-number">{state.performanceDto.queryCount}</h1>
                      </FlexItem>
                      <FlexItem>
                        <div className="c-ssa-ai-chat-performance__chart-cir" />
                      </FlexItem>
                    </FlexLayout>
                    <h4 id="total-queries-per-week-label">Total Queries / Week</h4>
                  </FlexItem>
                  <FlexItem>

                    <FlexLayout
                    modifiers={[FlexLayoutModifier.GUTTERS, FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem>
                        <h1 id="avg-students-per-day-number">{getAverageStudentsPerDay()}</h1>
                      </FlexItem>
                      <FlexItem>
                        <div className="c-ssa-ai-chat-performance__chart-mtn" />
                      </FlexItem>
                    </FlexLayout>
                    <h4 id="avg-students-per-day-label">Average Students / Day</h4>

                  </FlexItem>
                </FlexLayout>

              </div>
            </>
          )
}
      </>
    );
  };

  const themesMapKey = getMapKey(state.endDateOffset, state.selectedCourseSectionId);

  const getThemesStatus = (): StudentThemesStatus => {
    return state.themesStatusDtoMap
      && state.themesStatusDtoMap[themesMapKey]
      && state.themesStatusDtoMap[themesMapKey].status;
  };

  const isThemesCompleted = getThemesStatus() === StudentThemesStatus.COMPLETED
    && state.themesStatusDtoMap[themesMapKey].response.chatThemes.length > 0;

  const isNewThemesRequest = !noStudentEngagement()
    && getThemesStatus() === StudentThemesStatus.NOT_STARTED;

  const themesDisplay = () => {
    return (
      <>
        <h3>Prompt Themes</h3>
        {state.themesStatusDtoMap
          && state.themesStatusDtoMap[themesMapKey]
          && !isThemesCompleted
          && (
            <div className="o-els-container c-els-ai-chat__student-themes">
              {(noStudentEngagement()
                  || getThemesStatus() === StudentThemesStatus.INSUFFICIENT_ENTRIES)
                && (
                  <div className="c-els-ai-chat__student-themes-headings">
                    There are not enough valid student queries for Sherpath AI to find themes
                  </div>
                )}

              {getThemesStatus() === StudentThemesStatus.ERROR
                && (
                  <div>
                    <div className="c-els-ai-chat__student-themes-headings">An error occurred, please try again</div>
                    <ELSButton
                      type={ELSButtonType.PRIMARY}
                      size={ELSButtonSize.SMALL}
                      id="try-again-themes-btn"
                      className="c-els-ai-chat__student-themes-button"
                      onClick={() => handlePostThemesClick(true)}
                    >
                      Try again
                    </ELSButton>
                  </div>
                )}

              {isNewThemesRequest
                && (
                  <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_3X, FlexLayoutModifier.LEFT]}>
                    <FlexItem>
                      <ELSButton
                        type={ELSButtonType.PRIMARY}
                        size={ELSButtonSize.SMALL}
                        id="retrieve-themes-btn"
                        onClick={() => handlePostThemesClick(false)}
                      >
                        Show themes
                      </ELSButton>
                    </FlexItem>
                    <FlexItem>
                      <div className="c-els-ai-chat__student-themes-headings">
                        Results can take a few minutes
                      </div>
                    </FlexItem>
                  </FlexLayout>
                )}

              {getThemesStatus() === StudentThemesStatus.IN_PROGRESS && (
                <div>
                  <div className="c-els-ai-chat__student-themes-headings">
                    Retrieving themes... Results can take a few minutes
                  </div>
                  <ELSInlineLoader />
                </div>
              )}
            </div>
          )}

        {state.themesStatusDtoMap
          && state.themesStatusDtoMap[themesMapKey]
          && isThemesCompleted
          && (
            <div>
              {state.themesStatusDtoMap[themesMapKey].response.chatThemes.map((theme) => {
                return (
                  <div key={theme.title}
                       className="o-els-container c-els-ai-chat__student-themes">
                    <div className="c-els-ai-chat__student-themes-headings u-els-bold">
                      {theme.title}
                    </div>
                    <p>{theme.summary}</p>
                  </div>
                );
              })}
            </div>
          )}
      </>
    );
  };

  return (
    <AiChatBaseTemplate>
      <div className="c-ssa-ai-chat-performance">
        <div className="c-ssa-ai-chat-performance__width">

          <div>
            <button
              type="button"
              id="return-to-chat-btn"
              className="u-els-debuttonize u-els-anchorize"
              onClick={handleReturnToChatClick}
            >
              Return to chat
            </button>
          </div>

          <h1 id="instructor-insights-header">Instructor Insights</h1>

          <div className="o-els-container">
            <FlexLayout modifiers={[FlexLayoutModifier.LEFT]}>
              <FlexItem>
                <ELSDropDown
                  value={state.selectedCourseSectionId}
                  changeHandler={handleCourseSectionChange}
                  options={state.courseSectionOptions}
                >
                  Course section
                </ELSDropDown>
              </FlexItem>
            </FlexLayout>
          </div>

          <div className="o-els-container o-els-container--2x">

            <div>
              <FlexLayout modifiers={[
                FlexLayoutModifier.GUTTERS_2X,
                FlexLayoutModifier.MIDDLE,
                FlexLayoutModifier.LEFT,
                FlexLayoutModifier.WRAP_AT_MOBILE
              ]}>
                <FlexItem classes={['u-els-width-1o1@mobile', 'u-els-margin-bottom@mobile']}>
                  <h3>
                    {renderDateRange()}
                  </h3>
                </FlexItem>
                <FlexItem>
                  <button
                    type="button"
                    className="u-els-debuttonize u-els-anchorize u-els-font-size-h4"
                    onClick={() => {
                      handleTimeFrameChange(-7);
                    }}
                  >
                    <FlexLayout
                      modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.LEFT, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem>
                        <ELSIcon
                          prefix="hmds"
                          name="chevron-left"
                          align="middle"
                          size="1x"
                        />
                      </FlexItem>
                      <FlexItem>
                        Previous Week
                      </FlexItem>
                    </FlexLayout>
                  </button>
                </FlexItem>
                <FlexItem isRender={!isEndDateToday()}>
                  <button
                    type="button"
                    className="u-els-debuttonize u-els-anchorize u-els-font-size-h4"
                    onClick={() => {
                      handleTimeFrameChange(7);
                    }}
                  >
                    <FlexLayout
                      modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.RIGHT, FlexLayoutModifier.MIDDLE]}>
                      <FlexItem>
                        Next Week
                      </FlexItem>
                      <FlexItem>
                        <ELSIcon
                          prefix="hmds"
                          name="chevron-right"
                          align="middle"
                          size="1x"
                        />
                      </FlexItem>
                    </FlexLayout>
                  </button>
                </FlexItem>
              </FlexLayout>
            </div>
          </div>

          {performanceDisplay()}

          {isFeatureFlag(FEATURE_FLAG.IS_FLORENCE_GENERATE_THEMES_ENABLED, featureFlagsGrouped, courseSectionId)
            && performanceHasResponse
            && (
              themesDisplay()
            )}
        </div>
      </div>
    </AiChatBaseTemplate>
  );
};

const enhancers = [
  connector,
  ELSWithToastService,
  withPageLoader // This must come after connect
];

const AiChatPerformance = compose<null, null>(...enhancers)(AiChatPerformanceComponent);

export default AiChatPerformance;
