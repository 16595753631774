import React from 'react';
import { ELSProgressBar, } from '../../components/els.components';
import {
  ActivityTypeConfigMap,
  RecommendationItemStatusMap,
  RemediationStatusCounts
} from './remediation-home.models';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import {
  RecommendationDto,
  RecommendationItemDto,
  RemediationActivityType,
  RemediationAssignmentDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import {
  getAssessmentGoalsTotalsCombined,
  getContentTypeDisplay,
  getContentTypes,
  getProgressDisplay
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export type RemediationActivityProgressProps = {
  assessmentGoals: AssessmentGoalDto[];
  recommendations: RecommendationDto[];
  recommendationStatusMap: Record<string, RemediationStatusCounts>;
  activityType: RemediationActivityType;
  recommendationItems: RecommendationItemDto[];
  recommendationItemStatusMap: RecommendationItemStatusMap;
  remediationAssignment: RemediationAssignmentDto;
};

const RemediationActivityProgress = (props: RemediationActivityProgressProps) => {

  const {
    assessmentGoals,
    activityType,
    recommendations,
    recommendationItems,
    recommendationItemStatusMap,
    recommendationStatusMap,
    remediationAssignment
  } = props;

  const totals = getAssessmentGoalsTotalsCombined({
    assessmentGoals,
    activityType,
    recommendations,
    recommendationStatusMap,
    recommendationItems,
    recommendationItemStatusMap,
    remediationAssignment
  });

  const progressDisplay = getProgressDisplay(totals);
  const contentTypes = getContentTypes(activityType);
  const contentTypesDisplay = getContentTypeDisplay(contentTypes);

  return (
    <div className={`c-ssa-remediation-activity-progress c-ssa-remediation-activity-progress--${activityType.toLowerCase()}`}>
      <div className="c-ssa-remediation-activity-progress__header">
        <div>
          <FlexLayout
            modifiers={[
              FlexLayoutModifier.BOTTOM,
            ]}
          >
            <FlexItem
              modifiers={[
                FlexLayoutModifier.GROW,
              ]}>
              <strong>{ActivityTypeConfigMap[activityType].title}: {contentTypesDisplay}</strong>
            </FlexItem>
            <FlexItem>
              <div><span className="u-els-color-n7">{progressDisplay}</span></div>
            </FlexItem>
          </FlexLayout>

        </div>

        <div className="o-els-container o-els-container--1o2">
          <ELSProgressBar progressNum={totals.completed} totalNum={totals.total} />
        </div>
      </div>
    </div>
  );
};
export default RemediationActivityProgress;
