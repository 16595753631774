import React from 'react';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { IconPrefixes } from '../../constants/hmds.constants';

export type TotalsStatusProps = {
  completedGoal: number;
  completed: number;
  total: number;
}
export const TotalsStatus = (props: TotalsStatusProps) => {

  if (props.completed === 0) {
    return (
      <span className="u-els-display-inline-block c-els-pill u-els-color-n9 u-els-background-color-n0">
        {props.completed}/{props.total}
      </span>
    );
  }

  if (props.completed >= props.completedGoal) {
    return (
      <span className="u-els-display-inline-block c-els-pill u-els-color-confirm u-els-background-color-confirm-background">
        <IconWithText
          iconName="checkmark"
          iconPrefix={IconPrefixes.HMDS}
          iconSize="3o4"
        >
          {props.completed}/{props.total}
        </IconWithText>
      </span>
    );
  }

  return (
    <span className="u-els-display-inline-block c-els-pill u-els-color-info u-els-background-color-info-background">
      {props.completed}/{props.total}
    </span>
  );
};
