import React, {
  useEffect,
  useState
} from 'react';
import { ELSModalService } from '@els/els-component-modal-react';
import {
  ELSDropDown,
  ELSDropDownOption,
} from '@els/els-component-form-field-react';
import { ELSCommonConfig } from '@els/els-ui-common-react';
import {
  ELSToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import { AnalyticsActionProps } from '../../models/analytics.models';
import {
  NewQuizSessionDto,
  QuizSessionDto,
  QuizSessionResultsDto,
  RecommendationAttemptDto,
  RecommendationAttemptStatusDto,
  RecommendationDto,
  RecommendationItemDto,
  RemediationActivityType,
  RemediationAssignmentDto,
  RemRecContentTypeDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { RecommendationItemStatusMap } from './remediation-home.models';
import {
  getAssessmentGoalsItemsTotals,
} from './remediation-home.utilities';
import { AssessmentGoalDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { NavigateToApp } from '../../redux/student-study/studentStudy.models';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { SELECT_OPTION } from '../../utilities/app.utilities';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { RoutePath } from '../../components/app/app.constants';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import RemediationGoalModal from './RemediationGoalModal.component';
import { TotalsStatus } from './TotalsStatus.component';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';

export const RemediationQuizModalId = 'RemediationQuizModalId';

export type RemediationQuizModalProps = {
  modalService: ELSModalService;
  toastService: ELSToastService;
  trackAction: (props: AnalyticsActionProps) => void;
  redirect: (path: string, state?: Record<string, string>) => void;
  assessmentGoal: AssessmentGoalDto;
  recommendation: RecommendationDto;
  remediationAssignment: RemediationAssignmentDto;
  recommendationAttempts: RecommendationAttemptDto[];
  recommendationItems: RecommendationItemDto[];
  recommendationItemStatusMap: RecommendationItemStatusMap;
  quizSessionResults: QuizSessionResultsDto[];
  navigateToApp: NavigateToApp;
  postRemediationRecommendationAttemptAction: (attempt: Partial<RecommendationAttemptDto>) => Promise<RecommendationAttemptDto>;
  postRaaSQuizSessionAction: (quizSessionDto: NewQuizSessionDto) => Promise<QuizSessionDto>;
};

export type RemediationQuizModalState = {
  selectedQuizOption: string | number;
  recommendationAttempt: RecommendationAttemptDto;
}

const RemediationQuizModal = (props: RemediationQuizModalProps) => {

  const [state, setState] = useState<RemediationQuizModalState>({
    selectedQuizOption: SELECT_OPTION.value,
    recommendationAttempt: null
  });

  useEffect(() => {

    const { recommendationAttempts } = props;

    if (!recommendationAttempts || !recommendationAttempts.length) {
      props.postRemediationRecommendationAttemptAction({
        assessmentId: -1, // TODO: Update this to null
        remediationRecommendationId: props.recommendation.id,
        status: RecommendationAttemptStatusDto.NOT_STARTED
      }).then((response) => {
        setState((prevState) => {
          return {
            ...prevState,
            recommendationAttempt: response
          };
        });
      });
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        recommendationAttempt: recommendationAttempts[0]
      };
    });
  }, []);

  const handleCancelClick = () => {
    props.modalService.closeModal(RemediationQuizModalId);
  };

  const handleSelectQuizOption = (selectedOption: string | number) => {
    setState({
      ...state,
      selectedQuizOption: selectedOption
    });
  };

  const handleViewPerformance = () => {
    // eslint-disable-next-line no-alert
    alert('go to questions performance');
  };

  const handleStartQuiz = () => {

    if (!state.recommendationAttempt) {
      // eslint-disable-next-line no-console
      console.log('No recommendation attempt found');
      return;
    }

    let sessionQuestionCount = state.selectedQuizOption as number;

    if (sessionQuestionCount === SELECT_OPTION.value) {
      props.toastService.openToast({
        ...BASE_TOAST_CONFIG,
        component: (
          <div>
            <p>Please select number of questions.</p>
          </div>
        ),
        type: ELSWithToastService.types.NEGATIVE
      });
      return;
    }

    if (sessionQuestionCount === -1) {
      const recTotals = getAssessmentGoalsItemsTotals(
        {
          assessmentGoals: [props.assessmentGoal],
          activityType: RemediationActivityType.ASSESS,
          recommendations: [props.recommendation],
          recommendationItems: props.recommendationItems,
          statusMap: props.recommendationItemStatusMap,
          remediationAssignment: props.remediationAssignment
        }
      );

      sessionQuestionCount = recTotals.completed >= recTotals.total ? recTotals.total : recTotals.total - recTotals.completed;
    }

    props.postRaaSQuizSessionAction({
      recommendationAttemptId: state.recommendationAttempt.id,
      totalQuestions: sessionQuestionCount
    }).then((response) => {
      props.navigateToApp({
        action: AppAction.ASSESSMENT_START,
        app: Application.NHE_ASSESSMENT_PLAYER,
        body: {
          ref: RoutePath.REMEDIATION_HOME,
          quizSessionId: response.id,
          assessmentId: response.eolsAssessmentId,
          recommendationId: props.recommendation.id,
          recommendationAttemptId: state.recommendationAttempt.id,
          getNextQuestionAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/quiz-session/next-question?eolsAssessmentId=${response.eolsAssessmentId}`,
          submitQuestionAPI: `${ELSCommonConfig.buildUrl}/api/florence/remediation/quiz-session/submit-question`,
          assessmentPlayerConfig: {
            navigationType: 'Next-Back',
            strictStudyMode: true
          }
        },
        includeLinkHash: true
      });
    });
  };

  const recTotals = getAssessmentGoalsItemsTotals(
    {
      assessmentGoals: [props.assessmentGoal],
      activityType: RemediationActivityType.ASSESS,
      recommendations: [props.recommendation],
      recommendationItems: props.recommendationItems,
      statusMap: props.recommendationItemStatusMap,
      remediationAssignment: props.remediationAssignment
    }
  );

  const getMaxQuestions = (): number => {
    if (!state.recommendationAttempt) {
      return 0;
    }
    const items = props.recommendationItems.filter((item) => {
      return item.remediationRecommendationId === props.recommendation.id;
    });
    return props.quizSessionResults.reduce((acc, cur) => {
      if (cur.recommendationAttemptId !== state.recommendationAttempt.id) {
        return acc;
      }
      return cur.questions.reduce((_acc, _cur) => {
        if (_cur.correct) {
          return _acc - 1;
        }
        return _acc;
      }, acc);
    }, items.length);
  };

  const generateQuestionObjects = (maxNumber: number, stopAtExactMax: boolean): ELSDropDownOption[] => {
    const result = [];

    for (let i = 5; i < maxNumber; i += 5) {
      result.push({ name: `${i} Questions`, value: i });
    }

    if (stopAtExactMax) {
      result.push({ name: `${maxNumber} Questions (all)`, value: maxNumber });
    } else if (maxNumber % 5 !== 0) {
      result.push({ name: `${maxNumber} Questions`, value: maxNumber });
    }

    return result;
  };

  const getQuestionNumberOptions = (): ELSDropDownOption[] => {
    const maxQuestions = getMaxQuestions();
    let options = [];
    if (maxQuestions <= 0) {
      options = generateQuestionObjects(50, false);
    } else {
      options = generateQuestionObjects(maxQuestions, true);
    }
    return [
      SELECT_OPTION,
      ...options
    ];
  };

  const isComplete = recTotals.goal <= recTotals.completed && recTotals.total <= recTotals.attempted;

  return (
    <RemediationGoalModal
      assessmentGoal={props.assessmentGoal}
      totals={recTotals}
      isComplete={isComplete}
      handleCancelClick={handleCancelClick}
      contentTypes={[RemRecContentTypeDto.RAAS_QUIZ]}
    >
      <div className="c-ssa-remediation-quiz-modal">

        <div className="o-els-container">

          <table className="c-ssa-remediation-goal-modal__goal-table">
            <tbody>
              <tr>
                <td>
                  <IconWithText iconName="finger-point-up" iconPrefix="hmds">Instructions</IconWithText>
                </td>
                <td>You must attempt all <strong>{recTotals.total}</strong> questions and answer at least <strong>{recTotals.goal}</strong> correctly.</td>
              </tr>
              <tr>
                <td>
                  <IconWithText iconName="statistics" iconPrefix="gizmo">Questions Attempted</IconWithText>
                </td>
                <td>
                  <TotalsStatus completedGoal={recTotals.total} completed={recTotals.attempted} total={recTotals.total} />
                </td>
              </tr>
              <tr>
                <td>
                  <IconWithText iconName="statistics" iconPrefix="gizmo">Questions Correct</IconWithText>
                </td>
                <td>
                  <TotalsStatus completedGoal={recTotals.goal} completed={recTotals.completed} total={recTotals.total} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="o-els-container c-els-divider u-els-color-n0" />

        <div className="o-els-container o-els-container--1x1o2">

          <ELSDropDown
            name="select-quiz-questions"
            options={getQuestionNumberOptions()}
            changeHandler={(e, value: string) => handleSelectQuizOption(value)}
            value={state.selectedQuizOption}
          >
            I&apos;d like to complete:
          </ELSDropDown>
        </div>

        <div className="o-els-container">
          <FlexLayout modifiers={[FlexLayoutModifier.LEFT, FlexLayoutModifier.GUTTERS]}>
            <FlexItem>
              <button
                type="button"
                className="c-els-button c-els-button--primary c-els-button--small"
                onClick={handleStartQuiz}
              >
                Start quiz
              </button>
            </FlexItem>
            <FlexItem isRender={isComplete}>
              <button
                type="button"
                className="c-els-button c-els-button--secondary c-els-button--small"
                onClick={handleViewPerformance}
              >
                Review quiz
              </button>
            </FlexItem>
          </FlexLayout>

        </div>
      </div>
    </RemediationGoalModal>
  );
};

export default RemediationQuizModal;
