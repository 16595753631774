import React, {
  useEffect,
  useState
} from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import { compose } from 'recompose';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { ELSPageHeader } from '@els/els-component-shared-ts-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { studyActions } from '../../redux/student-study/studentStudy.actions';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { locationActions } from '../../redux/location/location.actions';
import { RoutePath } from '../../components/app/app.constants';
import {
  QuizSessionQuestionDto,
  QuizSessionResultsDto,
  RecommendationDto,
  RecommendationItemDto,
} from '../../apis/florence-facade/florence-facade.dtos';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { addSearchParams } from '../../utilities/app.utilities';
import { ELSProgressBar } from '../../components/els.components';
import { locationSelectors } from '../../redux/location/location.selectors';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';

type RemediationQuizSessionPropsOnly = {}

const mapDispatchToProps = {
  trackAction: studyActions.trackAction,
  redirect: locationActions.redirect,
  fetchRemediationRecommendationsAction: studyActions.fetchRemediationRecommendationsAction,
  fetchRemediationRecommendationItemsAction: studyActions.fetchRemediationRecommendationItemsAction,
  fetchQuizSessionByRecommendationAttemptIdAction: studyActions.fetchQuizSessionByRecommendationAttemptIdAction,
};
const mapStateToProps = state => ({
  messages: studySelectors.getMessages(state),
  appLinkData: studySelectors.getLinkData(state),
  location: locationSelectors.getLocation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type RemediationQuizSessionProps = PropsFromRedux & RemediationQuizSessionPropsOnly & ELSPropsFromModalService;

export type RemediationQuizSessionState = {
  recommendation: RecommendationDto;
  quizSessionResults: QuizSessionResultsDto[];
  quizItems: RecommendationItemDto[];
}

const defaultState: RemediationQuizSessionState = {
  recommendation: null,
  quizSessionResults: null,
  quizItems: null
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const RemediationQuizSessionComponent = (props: RemediationQuizSessionProps) => {

  const [state, setState] = useState<RemediationQuizSessionState>(defaultState);

  const mergeState = (newState: Partial<RemediationQuizSessionState>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState
      };
    });
  };

  const getQuizSessionResults = (quizSessionResults: QuizSessionResultsDto[], quizSessionId: string): {
    totalQuestions: number;
    totalCorrect: number;
  } => {
    if (!quizSessionResults || !quizSessionResults.length) {
      return null;
    }

    const quizSessionResult = quizSessionResults.find((result) => {
      return result.quizSessionId.toString() === quizSessionId;
    });

    if (!quizSessionResult) {
      return null;
    }
    const totalQuestions = quizSessionResult.questions.length;
    const totalCorrect = quizSessionResult.questions.filter((question) => question.correct).length;
    return {
      totalQuestions,
      totalCorrect
    };
  };

  const getQuizRecommendationAttemptProgress = (
    quizSessionResults: QuizSessionResultsDto[],
    quizItems: RecommendationItemDto[]
  ): {
    totalAttempted: number;
    totalCorrect: number;
    totalQuestions: number;
  } => {

    if (!quizSessionResults || !quizSessionResults.length) {
      return null;
    }

    if (!quizItems || !quizItems.length) {
      return null;
    }

    const resultsMap: Record<string, QuizSessionQuestionDto> = quizSessionResults.reduce((acc, quizSessionResult) => {
      return quizSessionResult.questions.reduce((_acc, question: QuizSessionQuestionDto) => {
        if (_acc[question.questionVtwId] && _acc[question.questionVtwId].correct) {
          return _acc;
        }
        return {
          ..._acc,
          [question.questionVtwId]: question
        };
      }, acc);
    }, {});

    const questionsAnswered = Object.values(resultsMap);

    return {
      totalQuestions: quizItems.length,
      totalAttempted: questionsAnswered.length,
      totalCorrect: questionsAnswered.filter((question) => {
        return question.correct;
      }).length
    };
  };

  useEffect(() => {

    const {
      fetchRemediationRecommendationItemsAction,
      fetchQuizSessionByRecommendationAttemptIdAction,
      location
    } = props;

    const {
      quizSessionRecommendationAttemptId,
      quizSessionRecommendationId
    } = location.query;

    fetchRemediationRecommendationItemsAction(quizSessionRecommendationId).then((quizItems: RecommendationItemDto[]) => {
      mergeState({ quizItems });
    });

    fetchQuizSessionByRecommendationAttemptIdAction(quizSessionRecommendationAttemptId).then((quizSessionResults) => {
      mergeState({ quizSessionResults });
    });
  }, []);

  const handleReturnNavigation = () => {
    props.redirect(RoutePath.REMEDIATION_HOME);
  };

  const {
    redirect,
    location
  } = props;

  const recommendationAttemptResults = getQuizRecommendationAttemptProgress(state.quizSessionResults, state.quizItems);
  const quizSessionResults = getQuizSessionResults(state.quizSessionResults, location.query.quizSessionId);

  return (
    <div className="c-ssa-remediation-quiz-session">
      <div className="c-ssa-remediation-quiz-session__header">
        <ELSPageHeader onCloseClick={handleReturnNavigation} title="Study Assignment" />
      </div>

      <div className="c-ssa-remediation-quiz-session__body">
        <div className="c-ssa-remediation-quiz-session__window">

          {recommendationAttemptResults && (
            <div className="o-els-container">
              <div>
                <strong>
                  {recommendationAttemptResults.totalCorrect}/{recommendationAttemptResults.totalQuestions} quiz questions correct
                </strong>
              </div>
              <div className="c-ssa-remediation-quiz-session__progress-bar">
                <ELSProgressBar
                  progressNum={recommendationAttemptResults.totalCorrect}
                  totalNum={recommendationAttemptResults.totalQuestions}
                />
              </div>
            </div>
          )}

          {quizSessionResults && (
            <div className="o-els-container o-els-container--2x">
              <h4>
                Great job! In your most recent quiz attempt, you answered <strong>{quizSessionResults.totalQuestions} questions</strong> and
                got <strong>{quizSessionResults.totalCorrect} correct</strong>.
              </h4>
            </div>
          )}

          <div className="o-els-container">
            <FlexLayout modifiers={[
              FlexLayoutModifier.GUTTERS,
              FlexLayoutModifier.CENTER,
            ]}>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--secondary c-els-button--small"
                  onClick={() => redirect(RoutePath.REMEDIATION_HOME)}
                >
                  Back to personalized learning plan
                </button>
              </FlexItem>
              <FlexItem>
                <button
                  type="button"
                  className="c-els-button c-els-button--primary c-els-button--small"
                  onClick={() => {
                    const { quizSessionRecommendationId } = props.location.query;
                    redirect(addSearchParams(RoutePath.REMEDIATION_HOME, { quizSessionRecommendationId }));
                  }}
                >
                  Back to quiz overview
                </button>

              </FlexItem>
            </FlexLayout>
          </div>
        </div>
      </div>

    </div>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Student Study Quiz Session' }),
  connector,
  ELSWithModalService
];

const RemediationQuizSession = compose<null, RemediationQuizSessionPropsOnly>(...enhancers)(RemediationQuizSessionComponent);

export default RemediationQuizSession;
